<template>
  <v-container>
    <v-row justify="center">
      <v-card
        :loading="!this.$store.getters.ownerId.length"
        class="mx-auto"
        max-width="374"
        elevation="0"
      >
        <v-card-text class="text-center">
          Если ранее вы записывались к мастеру, то можете ознакомиться со своими записями.
          Для этого необходимо пройти небольшую процедуру авторизации.
          Заполните форму ниже.
        </v-card-text>

        <v-form ref="form" v-model="valid" @submit.prevent="overlayHandler">
          <v-container fluid>
            <v-row>
              <v-col cols="12" sm="12">
                <v-text-field
                  v-model="phone"
                  :rules="phoneRules"
                  color="deep-purple lighten-2"
                  label="Ваш номер телефона"
                  required
                  persistent-hint
                  prepend-icon="mdi-phone"
                ></v-text-field>
              </v-col>

              <v-col cols="12" sm="12">
                <GoogleRecaptcha/>
              </v-col>
            </v-row>

            <v-checkbox
              v-model="checkbox"
              :rules="checkboxRules"
              color="deep-purple lighten-2"
            >
              <template v-slot:label>
                <div>
                  Я ознакомился и принимаю условия
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <a
                        target="_blank"
                        href="/agreement.pdf"
                        @click.stop
                        v-on="on"
                      >
                        пользовательского соглашения,</a>
                    </template>
                    Перейдите по ссылке чтобы ознакомиться
                  </v-tooltip>
                  а также даю согласие на обработку своих персональных данных
                </div>
              </template>
            </v-checkbox>

            <v-row justify="start">
              <v-btn
                color="deep-purple lighten-2" type="submit"
                :dark="!continueDisabled"
                :disabled="continueDisabled"
              >
                Продолжить
              </v-btn>
            </v-row>
          </v-container>
        </v-form>

      </v-card>

      <v-dialog v-model="overlay" persistent dark>
        <v-card>

          <!--          <v-container>-->
          <!--            <v-col cols="12" sm="4" class="mx-auto">-->
          <!--              <p>В ближайшее время Вам позвонят с номера:-->
          <!--                <b><u>+7(***)***XXXX</u></b>.-->
          <!--                Последние 4 цифры номера - это код,-->
          <!--                который нужно ввести в поле ниже.-->
          <!--              </p>-->
          <!--            </v-col>-->
          <!--          </v-container>-->

          <v-container>
            <v-col cols="12" sm="4" class="mx-auto">
              <p>
                В ближайшее время Вам позвонят с неизвестного номера необходимо
                <br>ВЗЯТЬ ТРУБКУ и ПРОСЛУШАТЬ
                <br>проверочный код из 4 цифр
                <br><b style="color: #ff5252">
                <u>не торопитесь отвечать, иначе робот решит, что сработал автоответчик, и сбросит вызов</u></b>
              </p>
            </v-col>
          </v-container>

          <v-container>
            <v-form @submit.prevent="checkCodeHandler">
              <v-row justify="center">
                <v-col cols="12" sm="4">
                  <v-text-field
                    v-model="code"
                    type="number"
                    color="deep-purple lighten-2"
                    label="Введите код XXXX сюда"
                    required
                    :hint="hintText"
                    persistent-hint
                  ></v-text-field>
                  <v-btn
                    v-if="timeout === 0"
                    text
                    color="deep-purple lighten-2"
                    small
                    @click.prevent="repeatCallHandler"
                  >
                    ПОВТОРИТЬ ВЫЗОВ
                  </v-btn>
                </v-col>
              </v-row>

              <v-row justify="center">
                <v-col cols="12" sm="4">
                  <v-btn
                    class="white--text"
                    color="deep-purple"
                    @click.prevent="checkCodeHandler"
                  >
                    Проверить код
                  </v-btn>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card>
      </v-dialog>
    </v-row>
  </v-container>
</template>

<script>
import {mapGetters} from "vuex"
import phoneFilter from "../../filters/phoneFilter"
import GoogleRecaptcha from "@/components/GoogleRecaptcha";

export default {
  name: "Introduce",
  components: {GoogleRecaptcha},
  data: () => ({
    overlay: false,
    zIndex: 0,
    phone: "",
    phoneRules: [
      v => !!v.length || "Заполните это поле",
      v => v.length === 12 || "Номер должен быть в формате +79*********"
    ],
    checkbox: true,
    checkboxRules: [
      c => !!c || "Для продолжения необходимо принять пользовательское соглашение"
    ],
    code: "",
    timeout: 60,
    hintText: "Повторный вызов можно будет выполнить через 30",
    valid: true,
    continueDisabled: false
  }),

  computed: {
    ...mapGetters(["ownerId"])
  },

  methods: {
    validatePhoneNumber() {
      this.phone = phoneFilter(this.phone)
    },

    async captchaCall() {

    },

    async overlayHandler() {
      this.$refs.form.validate()
      if (!this.valid) return

      //eslint-disable-next-line
      const recaptchaToken = grecaptcha.getResponse()
      if (!recaptchaToken){
        this.$store.commit('setServerMessage', 'Пожалуйста, подтвердите, что Вы не робот')
        return
      }

      this.continueDisabled = true
      this.$store.commit("setServerMessage", "Пожалуйста подождите идет проверка капчи")

      const response = await fetch(`${this.$store.state.server}/call/make`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          phone: this.phone,
          recaptchaToken
        })
      })

      const json = await response.json()
      this.$store.commit("setServerMessage", json.message)

      if (response.status !== 200) {
        return await this.$router.push("/")
      }

      this.overlay = true

      if (this.timeout === 0) this.timeout = 30
      else setInterval(() => {
        if (this.timeout > 0) {
          this.hintText = `Повторный вызов можно будет выполнить через ${this.timeout}`
          this.timeout -= 1
          if (this.timeout === 0) {
            this.hintText = ""
          }
        }
      }, 1000)
    },

    async checkCodeHandler() {
      if (this.code.toString().length !== 4) {
        return
      }

      let response = await fetch(`${this.$store.state.server}/auth/client-login`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          phone: this.phone,
          code: this.code,
        })
      })

      const json = await response.json()

      if (response.status === 200) {
        this.$store.commit("setServerMessage", json.message)
        localStorage.setItem("token", JSON.stringify(json.token))
        localStorage.setItem("phone", JSON.stringify(json.phone))
        localStorage.setItem("firstName", JSON.stringify(json.firstName))
        localStorage.setItem("lastName", JSON.stringify(json.lastName))

        await this.$router.push("/client-records")
      }

      if (response.status === 400) {
        this.$store.commit("setServerMessage", json.message)
        await this.$router.push("/")
      }

      if (response.status === 401) {
        this.$store.commit("setServerMessage", json.message)
      }
    },

    async repeatCallHandler() {
      // await this.captchaCall()
      this.continueDisabled = false
      this.overlay = false
    }
  },

  async mounted() {
    this.createRecaptchaScript()
  },

  watch: {
    phone: function () {
      this.phone = phoneFilter(this.phone)
    }
  }
}
</script>

<style scoped>

</style>
